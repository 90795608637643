import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"

import { Helmet } from "react-helmet"
// import { rhythm } from "../utils/typography";

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage

    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | ${currentPage.title}`}
          meta={[{ name: "description", content: currentPage.content }]}
        />
        <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
        {/* <PostIcons node={currentPage} css={{ marginBottom: rhythm(1 / 2) }} /> */}
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
